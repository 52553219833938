/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
  color: #000;
  background: #f6f7f9;
}

input, textarea {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  color: #000;
}

.hidden {
  display: none !important;
}

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
.layout_scroll_blocked {
  overflow: hidden;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.container {
  position: relative;
  flex: 1 1 0;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.container_type_tough {
  flex: none;
}
.container_type_sticky {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.container_type_centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container_type_global-grid {
  width: 290px;
}
.container_type_nav-grid {
  max-width: 525px;
}
.container_type_narrow-grid {
  max-width: 736px;
}
@media (min-width: 375px) {
  .container_type_global-grid {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .container_type_global-grid {
    width: 390px;
  }
}
@media (min-width: 768px) {
  .container_type_nav-grid {
    max-width: none;
  }
  .container_type_global-grid, .container_type_nav-grid {
    width: 680px;
  }
}
@media (min-width: 1024px) {
  .container_type_global-grid, .container_type_nav-grid {
    width: 920px;
  }
}
@media (min-width: 1200px) {
  .container_type_global-grid, .container_type_nav-grid {
    width: 1096px;
  }
}

.container_ratio_universal {
  padding-bottom: 56.25%;
}
.container_ratio_oldskul {
  padding-bottom: 75%;
}
.container_ratio_square {
  padding-bottom: 100%;
}
.container_ratio_portrait {
  padding-bottom: 120%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -32px;
}
.grid__item {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
}
.grid .splitter {
  flex: 1 0 100%;
}

.grid_align_center {
  align-items: center;
}

.grid_align_normal {
  align-items: normal;
}

.grid_align_end {
  align-items: end;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_3 {
    width: calc(100% + 32px);
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_3 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
  .grid.grid_cols_3 > .grid__item:last-child {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .grid.grid_cols_3 > .grid__item {
    width: calc(100% / 3 - 32px);
  }
  .grid.grid_cols_3 > .grid__item:last-child {
    margin-right: 32px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.section {
  position: relative;
  padding: 50px 0;
  background: #f6f7f9;
}
.section__heading {
  font-size: 36px;
  font-weight: bold;
  color: #000;
  padding-bottom: 20px;
}
.section__heading_align_center {
  text-align: center;
}
.section_spacing_tight .section__heading {
  padding-bottom: 0;
}
.section__subheading {
  font-size: 20px;
}
.section__background-image.image, .section__background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(0, auto, auto, 0);
  overflow: hidden;
}
.section__background-image.image > img {
  position: fixed;
}
.section__background-video > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section__splitter {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 48px 0;
}
.section__splitter_space_large {
  margin: 80px 0;
}
.section__background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 31, 32, 0.5);
}
.section__background-overlay_state_transparent {
  background-color: transparent;
}
.section__heading + .section__content, .section__subheading + .section__content, .section__content + .section__content, .section__content + .section__heading {
  padding-top: 64px;
}
.section_spacing_tight .section__heading + .section__content, .section_spacing_tight .section__subheading + .section__content, .section_spacing_tight .section__content + .section__content {
  padding-top: 24px;
}
.section__content_with_actions {
  margin-bottom: -16px;
}
.section__content_with_actions > .button {
  margin-bottom: 16px;
}
.section__content_with_actions > .button + .button {
  margin-left: 16px;
}
.section__heading + .section__paragraph {
  margin-top: 0;
}
.section_theme_lightest {
  background: #fff;
}
.section_theme_lightest .section__splitter {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}
.section.section_theme_hameleon {
  padding: 0;
  background: transparent;
}
.section__paragraph {
  margin: 16px 0;
  font-size: 16px;
  line-height: 32px;
}
.section__paragraph_with_buttons {
  line-height: 50px;
}
.section__paragraph:first-child {
  margin-top: 0;
}
.section__paragraph:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .section {
    padding: 70px 0;
  }
  .section.section_theme_hameleon {
    padding: 0;
  }
}

.section__heading_with_underline {
  position: relative;
  padding-bottom: 28px;
}
.section__heading_with_underline:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  bottom: 10px;
  width: 50px;
  height: 5px;
  background-color: #ec008b;
}

.block + .block {
  margin-top: 24px;
}
.block_with_buttons {
  line-height: 48px;
}

.align_text_center {
  text-align: center;
}

.align_text_right {
  text-align: right;
}

.align_text_left {
  text-align: left;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.image {
  position: relative;
  width: 100%;
  background-color: #f6f7f9;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.image > img:not([src]) {
  display: none;
}
.image_fit_contain > img {
  object-fit: contain;
}
.image_type_transparent {
  background-color: transparent;
}
.image_type_regular, .image_type_fill {
  background-color: transparent;
}
.image_type_regular > img, .image_type_fill > img {
  position: relative;
  height: auto;
  object-fit: contain;
}
.image_type_regular > img {
  width: auto;
  max-width: 100%;
}
.image_type_fill > img {
  width: 100%;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.link {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
}
.link > svg {
  fill: #333333;
  vertical-align: middle;
}
.link > svg + span {
  margin-left: 4px;
}
.link:hover {
  color: #ec008b;
}
.link:hover > svg {
  fill: #ec008b;
}
.link_display_inline {
  display: inline;
}
.link_with_icon > svg {
  width: 16px;
}
.link_with_underline {
  text-decoration: underline;
}
.link_type_accent {
  color: #ec008b;
}
.link_type_accent:hover {
  color: #000;
}
.link_type_brand {
  line-height: 32px;
  text-align: center;
}
.link_type_brand > svg {
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
}
.link_type_brand:hover > svg, .link_type_brand:active > svg {
  color: #fff;
  fill: #fff;
}
.link_type_underline {
  text-decoration: underline;
}
.link_brand_facebook > svg {
  background-color: #314f8e;
}
.link_brand_facebook:hover > svg {
  background-color: #3471b3;
}
.link_brand_twitter > svg {
  background-color: #000;
}
.link_brand_twitter:hover > svg {
  background-color: #222;
}
.link_brand_instagram > svg {
  background-color: #F58529;
}
.link_brand_instagram:hover > svg {
  background-color: #fa9446;
}
.link_brand_linkedin > svg {
  background-color: #007bb6;
}
.link_brand_linkedin:hover > svg {
  background-color: #008fcc;
}
.link_brand_whatsapp > svg {
  background-color: #2cb742;
}
.link_brand_whatsapp:hover > svg {
  background-color: #2bd144;
}
.link_brand_youtube > svg {
  background-color: #fe0000;
}
.link_brand_youtube:hover > svg {
  background-color: #f84222;
}
.link_brand_tiktok > svg {
  background-color: #000;
}
.link_brand_tiktok:hover > svg {
  background-color: #fe2c55;
}
.link_brand_pinterest > svg {
  background-color: #e60023;
}
.link_brand_pinterest:hover > svg {
  background-color: #ad081b;
}
.link_brand_facebook > svg, .link_brand_twitter > svg, .link_brand_instagram > svg, .link_brand_linkedin > svg, .link_brand_whatsapp > svg, .link_brand_youtube > svg, .link_brand_tiktok > svg, .link_brand_pinterest > svg {
  position: relative;
  top: -1px;
}
.link_brand_web > svg {
  background-color: #a2a2a2;
}
.link_brand_web:hover > svg {
  background-color: #adadad;
}
.link_for_mobile {
  display: none;
}
@media only screen and (pointer: coarse) {
  .link_for_desktop {
    display: none;
  }
  .link_for_mobile {
    display: inline-block;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.header {
  background-color: #fff;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  box-shadow: 0 2px 8px 0 rgba(50, 50, 50, 0.08);
}
.header__container {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.header__topbar {
  position: relative;
  height: 64px;
}
.header__logo {
  display: inline-block;
  position: relative;
  width: 190px;
  height: 100%;
}
.header__logo > svg, .header__logo > img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__toggle {
  position: absolute;
  top: 0;
  right: -12px;
  width: 80px;
  height: 100%;
  cursor: pointer;
}
.header__toggle > svg {
  width: 24px;
  fill: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__toggle > svg:nth-child(2) {
  display: none;
  width: 20px;
}
.header__toggle-input {
  display: none;
}
.header__menu {
  height: 0;
  overflow: hidden;
  font-size: 0;
  opacity: 0;
}
.header__menu .menu__item_type_icon-inner-link > svg,
.header__menu .menu__item_type_icon-external-link > svg {
  width: 0;
}
.header__menu .menu__item_type_icon-inner-link > svg + span,
.header__menu .menu__item_type_icon-external-link > svg + span {
  margin-left: 0;
}
.header__toggle-input:checked ~ .header__menu {
  height: auto;
  opacity: 1;
  padding-top: 16px;
  transition: padding 0.15s, opacity 0.2s 0.15s;
}
.header__toggle-input:checked ~ .header__topbar > .header__toggle > svg:nth-child(1) {
  display: none;
}
.header__toggle-input:checked ~ .header__topbar > .header__toggle > svg:nth-child(2) {
  display: block;
}
@media (min-width: 768px) {
  .header__container {
    padding: 12px 0;
  }
}
@media (min-width: 1024px) {
  .header {
    overflow: visible;
  }
  .header__toggle {
    display: none;
  }
  .header__container {
    flex-direction: row;
    align-items: center;
  }
  .header__toopbar, .header__menu {
    flex: 1 1 0;
  }
  .header__menu, .header__toggle-input:checked ~ .header__menu {
    height: fit-content;
    transition: none;
    opacity: 1;
    padding-top: 0;
    overflow: visible;
    text-align: right;
  }
  .header__menu .menu__item_type_icon-inner-link, .header__menu .menu__item_type_icon-external-link {
    display: inline-flex;
    font-size: 0;
  }
  .header__menu .menu__item_type_icon-inner-link > svg, .header__menu .menu__item_type_icon-external-link > svg {
    width: 18px;
  }
}

.header__topbar {
  height: 120px;
}

@media (min-width: 320px) {
  .header__logo {
    width: 180px;
  }
}
@media (min-width: 425px) {
  .header__logo {
    width: 200px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.footer {
  font-size: 16px;
}
.footer__links + .footer__navigation {
  margin-top: 48px;
}
.footer__navigation-block + .footer__navigation-block {
  margin-top: 16px;
}
.footer__text, .footer__navigation-line {
  line-height: 2em;
}
.footer__copyright {
  text-align: center;
  margin-bottom: 24px;
}
.footer__poweredby {
  text-align: center;
}
.footer__poweredby > .image {
  display: block;
  margin-top: 12px;
  padding-bottom: 45px;
}
.footer__heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.footer .link_type_quick, .footer .link_type_contact > span, .footer .link_type_town {
  text-decoration: underline;
}
.footer .link_type_contact, .footer .link_type_quick {
  display: block;
  margin-top: 16px;
}
.footer .link_type_town {
  font-weight: bold;
}
.footer .link_type_brand:hover > svg, .footer .link_type_brand:active > svg {
  fill: #fff;
}
.footer__partners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
}
.footer__partner {
  display: inline-block;
  margin: 32px;
}
@media (min-width: 768px) {
  .footer__bottom {
    position: relative;
  }
  .footer__copyright {
    height: 45px;
    line-height: 45px;
    margin: 0;
    text-align: left;
  }
  .footer__poweredby {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 45px;
    white-space: nowrap;
  }
  .footer__poweredby > .image {
    width: 80px;
    margin-top: 0;
    margin-left: 12px;
  }
}

.footer__top {
  padding: 50px 0;
  background-color: #333333;
  color: #fff;
}
.footer__top .link {
  color: #fff;
}
.footer__top .link:hover {
  color: #ec008b;
}
.footer__top .link_type_contact > svg {
  fill: #fff;
}
.footer__top .link_type_contact:hover > svg {
  fill: #ec008b;
}
.footer__bottom {
  padding: 20px 0;
  background-color: #262626;
  color: rgba(255, 255, 255, 0.8);
}
.footer__heading {
  color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.menu__item {
  display: block;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
}
.menu__item_type_group > svg {
  width: 8px;
  height: 100%;
  vertical-align: top;
}
.menu__item_type_group > svg:nth-child(2) {
  display: none;
}
.menu__item_type_line-break {
  display: block;
  height: 0;
}
.menu__item.link {
  color: #333333;
}
.menu__item.link > svg {
  fill: #333333;
}
.menu__item.link:hover {
  color: #ec008b;
}
.menu__item.link:hover > svg {
  fill: #ec008b;
}
.menu__group-toggle-input {
  display: none;
}
.menu__group-items {
  display: none;
  padding-left: 16px;
  z-index: 1;
}
.menu__group-toggle-input:checked ~ .menu__group-items {
  display: block;
}
.menu__group-toggle-input:checked ~ .menu__item_type_group > svg:nth-child(1) {
  display: none;
}
.menu__group-toggle-input:checked ~ .menu__item_type_group > svg:nth-child(2) {
  display: inline-block;
}
@media (min-width: 1024px) {
  .menu__item {
    display: inline-block;
    white-space: nowrap;
    padding: 0 16px;
    vertical-align: top;
  }
  .menu__item_type_line-break {
    display: block;
  }
  .menu__group {
    display: inline-block;
    position: relative;
  }
  .menu__group-items {
    padding-left: 0;
    position: absolute;
    top: 45px;
    left: 16px;
    text-align: left;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    max-height: 410px;
    overflow-y: auto;
  }
  .menu__group-items > .menu__item.link {
    color: #333333;
  }
  .menu__group-items > .menu__item.link > svg {
    fill: #333333;
  }
  .menu__group-items > .menu__item.link:hover {
    color: #ec008b;
  }
  .menu__group-items > .menu__item.link:hover > svg {
    fill: #ec008b;
  }
  .menu__group-items > .menu__item {
    display: block;
  }
  .menu__group-items > .menu__item:hover {
    background-color: #f7f7f9;
  }
}